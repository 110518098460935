import {Redirect, Route, Switch} from "react-router-dom";
import Home from "../pages/internalPages/Home";
import DashboardDark from "../components/Dashboard/DashboardDark";
import Error400 from "../pages/ErrorPages/Error400";
import Error403 from "../pages/ErrorPages/Error403";
import Error404 from "../pages/ErrorPages/Error404";
import Error500 from "../pages/ErrorPages/Error500";
import Error503 from "../pages/ErrorPages/Error503";
import React from "react";
import Login from "../pages/externalPages/auth/Login";
import {appLocalStoargeValues} from "../config/Constants";
import {getDataLocalStorage} from "../utils/LocalStorageFunctions";
import {hasAValue} from "../utils/SharedFunctions";
import OrdersOverview from "../pages/internalPages/Orders/OrdersOverview";
import BonusProcessing from "../pages/internalPages/BonusSystem/BonusProcessing";
import BonusRules from "../pages/internalPages/BonusSystem/BonusRules";


export function privatePages(){
    let userData = getDataLocalStorage(appLocalStoargeValues.user_data)

    return(
        <Switch>

            {/*publicPages*/}
            {!hasAValue(userData) && <Route path='/page-login' component={Login}/>}

            {/*privatePages*/}
            {hasAValue(userData) && <Route path='/dashboard' component={Home}/>}
            {hasAValue(userData) &&<Route path='/dashboard-dark' component={DashboardDark}/>}
            {hasAValue(userData) &&<Route path='/page-error-400' component={Error400}/>}
            {hasAValue(userData) &&<Route path='/page-error-403' component={Error403}/>}
            {hasAValue(userData) &&<Route path='/page-error-404' component={Error404}/>}
            {hasAValue(userData) && <Route path='/page-error-500' component={Error500}/>}
            {hasAValue(userData) && <Route path='/page-error-503' component={Error503}/>}
            {hasAValue(userData) &&<Route path='/orders-overview' component={OrdersOverview}/>}
            {hasAValue(userData) &&<Route path='/bonus-processing' component={BonusProcessing}/>}
            {hasAValue(userData) &&<Route path='/bonus-rules' component={BonusRules}/>}


            {!hasAValue(userData) && <Redirect to="/page-login"/>}
            {hasAValue(userData) && <Redirect to="/dashboard"/>}

        </Switch>
    )
}
