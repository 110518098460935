import React, {useEffect, useMemo, useState} from 'react';
import {getAllBonusLawCall, getAllBonusRulesByLawIdCall} from "../../../Api/ApiCalls";
import {toast} from "react-toastify";
import {toastOptions} from "../../../config/Constants";
import {hasAValue} from "../../../utils/SharedFunctions";
import * as XLSX from "xlsx";
import OrdersTable from "./BonusTable/OrdersTable";
import {
    bonus_processing_col_asap,
    bonus_processing_col_delivery,
    bonus_processing_col_pre
} from "../../../components/FilteringTable/Columns";
import Swal from "sweetalert2";
import {closeSwalModal, launchErrorModal, launchLoadingModal} from "../../../utils/SwalFuncations";


export default function BonusProcessing() {

    const [uploadedFile, setUploadedFile] = useState(undefined)
    const [data, setData] = useState([]);
    const [bonusLaws, setBonusLaws] = useState([])
    const [totalOrderInNumbers, setTotalOrderInNumbers] = useState(0)
    const [totalAsapOrderInNumbers, setTotalAsapOrderInNumbers] = useState(0)
    const [totalPreOrderInNumbers, setTotalPreOrderInNumbers] = useState(0)
    const [totalDeliveryOrderInNumbers, setTotalDeliveryOrderInNumbers] = useState(0)

    const [usedBonusRulesAsapOrders, setUsedBonusRulesAsapOrders] = useState([])
    const [usedBonusRulesPreOrders, setUsedBonusRulesPreOrders] = useState([])
    const [usedBonusRulesDeliveryOrders, setUsedBonusRulesDeliveryOrders] = useState([])


    useEffect(() => {
        getAllBonusLaw()
    }, [])


    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">bonus rules</h4>
                    <input className="btn btn-secondary" type={"file"}
                           name={"file"}
                           onChange={(val) => setUploadedFile(val.target.files[0])}/>

                </div>
                <div className="card-body">
                    <div className={"row "}>
                        <div className={"col-md-3 col-sm-10 m-1"}>
                            <h4 className="card-title">Asap orders rules</h4>

                        </div>
                        <div className={"col-md-7 col-sm-10 m-1"}>
                            <select
                                className="form-control form-control"
                                required
                                onChange={(val) => {
                                    getAllBonusRulesByLawIdForAsap(val.target.value)
                                }}
                            >
                                <option value={null}>{"Choose one"}</option>

                                {bonusLaws.map((item, i) => {
                                    return (
                                        <option key={i} value={item.id}>{item.name}</option>

                                    )
                                })}
                            </select>
                        </div>

                        <div className={"col-md-3 col-sm-10 m-1"}>
                            <h4 className="card-title">Pre orders rules</h4>

                        </div>
                        <div className={"col-md-7 col-sm-10 m-1"}>
                            <select
                                className="form-control form-control"
                                required
                                onChange={(val) => {
                                    getAllBonusRulesByLawIdForPre(val.target.value)
                                }}
                            >
                                <option value={null}>{"Choose one"}</option>

                                {bonusLaws.map((item, i) => {
                                    return (
                                        <option key={i} value={item.id}>{item.name}</option>

                                    )
                                })}
                            </select>
                        </div>

                        <div className={"col-md-3 col-sm-10 m-1"}>
                            <h4 className="card-title">Delivery orders rules</h4>

                        </div>
                        <div className={"col-md-7 col-sm-10 m-1"}>
                            <select
                                className="form-control form-control"
                                required
                                onChange={(val) => {
                                    getAllBonusRulesByLawIdForDelivery(val.target.value)
                                }}
                            >
                                <option value={null}>{"Choose one"}</option>

                                {bonusLaws.map((item, i) => {
                                    return (
                                        <option key={i} value={item.id}>{item.name}</option>

                                    )
                                })}
                            </select>
                        </div>

                        <div className={"col-md-12 text-end"}>
                            <a className="btn btn-secondary" onClick={() => processFile()}> Process file</a>
                        </div>
                    </div>
                </div>
                <div className="card-header">
                    <div className="row w-100">
                        <h4 className="card-title col-md-3 col-sm-6">Total number of orders {totalOrderInNumbers}</h4>
                        <h4 className="card-title col-md-3 col-sm-6">ASAP {totalAsapOrderInNumbers}</h4>
                        <h4 className="card-title col-md-3 col-sm-6">PRE {totalPreOrderInNumbers}</h4>
                        <h4 className="card-title col-md-3 col-sm-6">DELIVERY {totalDeliveryOrderInNumbers}</h4>

                    </div>
                </div>
            </div>

            {/*<div className="card">*/}
            {/*  */}
            {/*    <div className="card-body">*/}
            {/*    </div>*/}
            {/*</div>*/}

            <OrdersTable data={data} columns={bonus_processing_col_asap} titleTable={"ASAP orders"}/>
            <OrdersTable data={data} columns={bonus_processing_col_pre} titleTable={"Pre orders"}/>
            <OrdersTable data={data} columns={bonus_processing_col_delivery} titleTable={"Delivery orders"}/>
        </>
    )

    function processFile() {
        // launchLoadingModal()
        if (usedBonusRulesAsapOrders.length === 0 && usedBonusRulesPreOrders.length === 0) {

            toast.error("Fill in everything", toastOptions);

        } else {
            const reader = new FileReader();
            reader.onload = async ({target}) => {

                // This will take care of the XLSX file
                const wb = XLSX.read(target.result, {type: 'binary'});
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const parsedData = XLSX.utils.sheet_to_json(ws, {header: true});

                setTotalOrderInNumbers(parsedData.length)

                // Start grouping record based on the driver id
                let groupByCategory = parsedData.reduce((group, order) => {
                    const category = order["driver id"];
                    group[category] = group[category] ?? [];
                    group[category].push(order);
                    return group;
                }, {});
                let newList = []

               let totalAsapOrderInNumbersLocal = 0
               let totalPreOrderInNumbersLocal = 0
               let totalDeliveryOrderInNumbersLocal = 0

                for (let i = 0; i < Object.keys(groupByCategory).length; i++) {
                    let driver_name = (Object.values(groupByCategory)[i])[0]["driver name"]
                    // key = driver id
                    let key = Object.keys(groupByCategory)[i]
                    // total order per driver id (ASAP, Pre ....)
                    let totalOrders = Object.values(groupByCategory)[i].length

                    // ASAP orders per driver id
                    let asapOrders = (Object.values(groupByCategory)[i]).filter(item => {
                        return (hasAValue(item["requested pickup time"]) && hasAValue(item["requested vehicle type"] )) &&
                            item["requested pickup time"].toString().includes("ASAP") &&
                            !item["requested vehicle type"].toString().includes("COURIER")
                    })

                    // total ASAP orders
                    totalAsapOrderInNumbersLocal += asapOrders.length

                    // PRE orders per driver id
                    let preOrders = (Object.values(groupByCategory)[i]).filter(item => {
                        return (hasAValue(item["requested pickup time"]) && item["requested pickup time"].toString().substring(0, 5).includes(item["closed at"].toString().substring(0, 5)))
                    })

                    // total PRE orders
                    totalPreOrderInNumbersLocal += preOrders.length

                    // Delivery orders per driver id
                    let deliveryOrders = (Object.values(groupByCategory)[i]).filter(item => {
                        return (hasAValue(item["requested pickup time"]) && hasAValue(item["requested vehicle type"] )) &&
                            item["requested pickup time"].toString().includes("ASAP") &&
                            item["requested vehicle type"].toString().includes("COURIER")
                    })

                    // total DELIVERY orders
                    totalDeliveryOrderInNumbersLocal += deliveryOrders.length


                    // trying to find out the distance
                    let asapEstimationDistance = 0
                    let preEstimationDistance = 0
                    let deliveryEstimationDistance = 0

                    // Calculating the distance per driver id for ASAP orders
                    asapOrders.map(item => {
                        let estimationDistanceValue = item["estimation distance"]
                        let estimationDistanceLocal = (hasAValue(estimationDistanceValue) && estimationDistanceValue !== "") && estimationDistanceValue.toString().replace("km", "")
                        if (estimationDistanceLocal) {
                            asapEstimationDistance += parseFloat(estimationDistanceLocal)
                        }
                    })

                    // Calculating the distance per driver id for PRE orders
                    preOrders.map(item => {
                        let estimationDistanceValue = item["estimation distance"]
                        let estimationDistanceLocal = (hasAValue(estimationDistanceValue) && estimationDistanceValue !== "") && estimationDistanceValue.toString().replace("km", "")
                        if (estimationDistanceLocal) {
                            preEstimationDistance += parseFloat(estimationDistanceLocal)
                        }
                    })

                    // Calculating the distance per driver id for DELIVERY orders
                    deliveryOrders.map(item => {
                        let estimationDistanceValue = item["estimation distance"]
                        let estimationDistanceLocal = (hasAValue(estimationDistanceValue) && estimationDistanceValue !== "") && estimationDistanceValue.toString().replace("km", "")
                        if (estimationDistanceLocal) {
                            deliveryEstimationDistance += parseFloat(estimationDistanceLocal)
                        }
                    })

                    // The distance
                    asapEstimationDistance = asapEstimationDistance.toFixed(2)
                    preEstimationDistance = preEstimationDistance.toFixed(2)
                    deliveryEstimationDistance = deliveryEstimationDistance.toFixed(2)

                    let bonusAmountASAP = 0
                    let bonusAmountPre = 0
                    let bonusAmountDelivery = 0

                    // Calculating the bonus per driver id for ASAP orders and based on the rules
                    for (const usedBonusRulesAsapOrder of usedBonusRulesAsapOrders) {
                        if (usedBonusRulesAsapOrder.trips < totalOrders && usedBonusRulesAsapOrder.km < asapEstimationDistance) {
                            let correctedKmToUse = asapEstimationDistance - usedBonusRulesAsapOrder.km
                            if (correctedKmToUse > 8) {
                                bonusAmountASAP = parseInt(8 * usedBonusRulesAsapOrder.bonus).toFixed(0)
                                break;
                            } else {
                                bonusAmountASAP = parseInt(correctedKmToUse * usedBonusRulesAsapOrder.bonus).toFixed(0)
                                break;
                            }

                        }
                    }

                    // Calculating the bonus per driver id for PRE orders and based on the rules
                    for (const usedBonusRulesPreOrder of usedBonusRulesPreOrders) {
                        if (usedBonusRulesPreOrder.trips < totalOrders && usedBonusRulesPreOrder.km < preEstimationDistance) {
                            let correctedKmToUse = preEstimationDistance - usedBonusRulesPreOrder.km
                            if (correctedKmToUse > 8) {
                                bonusAmountPre = parseInt(8 * usedBonusRulesPreOrder.bonus).toFixed(0)
                                break;
                            } else {
                                bonusAmountPre = parseInt(correctedKmToUse * usedBonusRulesPreOrder.bonus).toFixed(0)
                                break;
                            }
                        }
                    }


                    // Calculating the bonus per driver id for DELIVERY orders and based on the rules
                    for (const usedBonusRulesDeliveryOrder of usedBonusRulesDeliveryOrders) {
                        if (usedBonusRulesDeliveryOrder.trips < totalOrders && usedBonusRulesDeliveryOrder.km < deliveryEstimationDistance) {
                            let correctedKmToUse = deliveryEstimationDistance - usedBonusRulesDeliveryOrder.km
                            if (correctedKmToUse > 8) {
                                bonusAmountDelivery = parseInt(8 * usedBonusRulesDeliveryOrder.bonus).toFixed(0)
                                break;
                            } else {
                                bonusAmountDelivery = parseInt(correctedKmToUse * usedBonusRulesDeliveryOrder.bonus).toFixed(0)
                                break;
                            }
                        }
                    }

                    // number of orders per driver
                    let asapOrdersNumbers= asapOrders.length
                    let preOrdersNumbers= preOrders.length
                    let deliveryOrdersNumbers= deliveryOrders.length


                    newList.push({
                        key, driver_name, totalOrders, asapOrders, preOrders, deliveryOrders,
                        asapOrdersNumbers, preOrdersNumbers, deliveryOrdersNumbers,
                        asapEstimationDistance, preEstimationDistance, deliveryEstimationDistance,
                        bonusAmountASAP, bonusAmountPre, bonusAmountDelivery
                    })
                }

                setTotalAsapOrderInNumbers(totalAsapOrderInNumbersLocal)
                setTotalPreOrderInNumbers(totalPreOrderInNumbersLocal)
                setTotalDeliveryOrderInNumbers(totalDeliveryOrderInNumbersLocal)

                setData(newList);
            };
            reader.readAsBinaryString(uploadedFile);

        }
    }


    function getAllBonusLaw() {
        getAllBonusLawCall().then(list => {
            setBonusLaws(list)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }

    function getAllBonusRulesByLawIdForAsap(id) {
        getAllBonusRulesByLawIdCall(id).then(list => {
            setUsedBonusRulesAsapOrders(list)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }

    function getAllBonusRulesByLawIdForPre(id) {
        getAllBonusRulesByLawIdCall(id).then(list => {
            setUsedBonusRulesPreOrders(list)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }

    function getAllBonusRulesByLawIdForDelivery(id) {
        getAllBonusRulesByLawIdCall(id).then(list => {
            setUsedBonusRulesDeliveryOrders(list)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }
}

