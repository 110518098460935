import {getDataLocalStorage, removeDataLocalStorage} from "./LocalStorageFunctions";
import {appLocalStoargeValues, toastOptions} from "../config/Constants";
import {toast} from "react-toastify";

export function hasAValue(value){
    if(value !== null && value !== undefined){
        return true
    }else{
        return false
    }
}


export function getLanguage(){
    // let language = getDataLocalStorage(appLocalStoargeValues.language)
    let language = undefined
    if(!hasAValue(language)){
        return "nl";
    }else{
        return language
    }
}


export async function userLoggedIn() {
    let userData = await getDataLocalStorage(appLocalStoargeValues.user_data)
    if(hasAValue(userData)){
        return true;
    }else{
        return false;
    }
}

export async function userLoggedInfo() {
    let userData = await getDataLocalStorage(appLocalStoargeValues.user_data)
    if(hasAValue(userData)){
        return userData;
    }else{
        return undefined;
    }
}


export  function loginAgain(error) {
    if(parseInt(error.response.status) === 401){
        removeDataLocalStorage(appLocalStoargeValues.user_data)
        toast.warn("Please login in again", toastOptions)
        setTimeout(()=>{
            window.location= "/"
        },3000)
    }
}


export function convertDateToView(value) {
    return new Date(parseInt(value)).toLocaleDateString();
}

export function convertDateAndTimeToView(value) {
    return new Date(parseInt(value)).toUTCString();
}

export function getStatusOfOrder(value) {
    if(value.toString().includes("sent")) return <span className={"badge badge-outline-primary"}>{value}</span>
    else if(value.toString().includes("inprogress")) return <span className={"badge badge-outline-warning"}>{value}</span>
    else if(value.toString().includes("ready")) return <span className={"badge badge-info"}>{value}</span>
    else  return <span className={"badge badge-outline-danger"}>{value}</span>
}

export function getStatusOfPayment(value) {
    if(hasAValue(value) && value.toString().includes("unpaid")) return <span className={"badge badge-danger"}>{value}</span>
    else if(hasAValue(value) && value.toString().includes("paid")) return <span className={"badge badge-primary"}>{value}</span>
    else return <span className={"badge badge-danger"}>{"unpaid"}</span>
}
