import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
import {
	convertDateAndTimeToView,
	getStatusOfOrder, getStatusOfPayment, hasAValue,
} from "../../utils/SharedFunctions";
export const COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'First Name',
		Footer : 'First Name',
		accessor: 'first_name',
		Filter: ColumnFilter,
	},
	{
		Header : 'Last Name',
		Footer : 'Last Name',
		accessor: 'last_name',
		Filter: ColumnFilter,
	},
	{
		Header : 'Email Id',
		Footer : 'Email Id',
		accessor: 'email',
		Filter: ColumnFilter,
	},
	{
		Header : 'Date of  Birth',
		Footer : 'Date of  Birth',
		accessor: 'date_of_birth',
		Cell: ({ value }) => {return format(new Date(value), 'dd/mm/yyyy')},
		Filter: ColumnFilter,
	},
	{
		Header : 'Country',
		Footer : 'Country',
		accessor: 'country',
		Filter: ColumnFilter,
	},
	{
		Header : 'Phone',
		Footer : 'Phone',
		accessor: 'phone',
		Filter: ColumnFilter,
	},
]



export const orders_col = [
	{
		Header : 'Date of the order',
		Footer : 'Date of the order',
		accessor: 'create_date',
		Filter: ColumnFilter,
		Cell: ({ value }) => {return  convertDateAndTimeToView(value)},
	},
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'License plate',
		Footer : 'License plate',
		accessor: 'license_plate',
		Filter: ColumnFilter,
	},

	{
		Header : 'City',
		Footer : 'City',
		accessor: 'city',
		Filter: ColumnFilter,
	},

	{
		Header : 'Verified',
		Footer : 'Verified',
		accessor: 'verified',
		Filter: ColumnFilter,
		Cell: ({ value }) => {return value === 0? "No": "Yes"},
	},

	{
		Header : 'Payment status',
		Footer : 'Payment status',
		accessor: 'payment_status',
		Filter: ColumnFilter,
		Cell: ({ value }) => {return getStatusOfPayment(value)},
	},
	{
		Header : 'Status',
		Footer : 'Status',
		accessor: 'status',
		Cell: ({ value }) => {return getStatusOfOrder(value)},
		Filter: ColumnFilter,
	},
	{
		Header : 'Link to our website',
		Footer : 'Link to our website',
		accessor: 'car_code',
		Filter: ColumnFilter,
		Cell: ({ value }) => {return <a target="_blank" href={"https://qrvehicle.com/vehicle-info/"+ value}> link</a> },
	},
	{
		Header : 'Url',
		Footer : 'Url',
		accessor: 'url',
		Filter: ColumnFilter,
		Cell: ({ value }) => {return <a target="_blank" href={value}>{value}</a> },
	},
	{
		Header : 'Payment intent',
		Footer : 'Payment intent',
		accessor: 'payment_intent',
		Filter: ColumnFilter,
	},
	{
		Header : 'Payment ref',
		Footer : 'Payment ref',
		accessor: 'payment_ref',
		Filter: ColumnFilter,
	},
]

export const bonus_rules_col = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Name',
		Footer : 'Name',
		accessor: 'name',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Created at',
		Footer : 'Created at',
		accessor: 'created_at',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
]

export const bonus_processing_col_asap = [
	{
		Header : 'Driver id',
		Footer : 'Driver id',
		accessor: 'key',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Driver name',
		Footer : 'Driver name',
		accessor: 'driver_name',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Total orders',
		Footer : 'Total orders',
		accessor: 'totalOrders',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Asap orders',
		Footer : 'Asap orders',
		accessor: 'asapOrdersNumbers',
		Filter: ColumnFilter,
		// Cell: ({ value }) => {return hasAValue(value) && value.length},
		//disableFilters: true,
	},

	{
		Header : 'Total KM ASAP',
		Footer : 'Total KM ASAP',
		accessor: 'asapEstimationDistance',
		Filter: ColumnFilter,
		// Cell: ({ value }) => {return hasAValue(value) && value.length},
		//disableFilters: true,
	},
	{
		Header : 'Bonus amount ASAP',
		Footer : 'Bonus amount ASAP',
		accessor: 'bonusAmountASAP',
		Filter: ColumnFilter,
		// Cell: ({ value }) => {return hasAValue(value) && value.length},
		//disableFilters: true,
	},
]

export const bonus_processing_col_pre = [
	{
		Header : 'Driver id',
		Footer : 'Driver id',
		accessor: 'key',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Driver name',
		Footer : 'Driver name',
		accessor: 'driver_name',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Total orders',
		Footer : 'Total orders',
		accessor: 'totalOrders',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Pre orders',
		Footer : 'Pre orders',
		accessor: 'preOrdersNumbers',
		Filter: ColumnFilter,
		// Cell: ({ value }) => {return hasAValue(value) && value.length},
		//disableFilters: true,
	},
	{
		Header : 'Total KM Pre orders',
		Footer : 'Total KM Pre orders',
		accessor: 'preEstimationDistance',
		Filter: ColumnFilter,
		// Cell: ({ value }) => {return hasAValue(value) && value.length},
		//disableFilters: true,
	},
	{
		Header : 'Bonus amount Pre orders',
		Footer : 'Bonus amount Pre orders',
		accessor: 'bonusAmountPre',
		Filter: ColumnFilter,
		// Cell: ({ value }) => {return hasAValue(value) && value.length},
		//disableFilters: true,
	},


]

export const bonus_processing_col_delivery = [
	{
		Header : 'Driver id',
		Footer : 'Driver id',
		accessor: 'key',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Driver name',
		Footer : 'Driver name',
		accessor: 'driver_name',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Total orders',
		Footer : 'Total orders',
		accessor: 'totalOrders',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Delivery orders',
		Footer : 'Delivery orders',
		accessor: 'deliveryOrdersNumbers',
		Filter: ColumnFilter,
		// Cell: ({ value }) => {return hasAValue(value) && value.length},
		//disableFilters: true,
	},
	{
		Header : 'Total KM Delivery orders',
		Footer : 'Total KM Delivery orders',
		accessor: 'deliveryEstimationDistance',
		Filter: ColumnFilter,
		// Cell: ({ value }) => {return hasAValue(value) && value.length},
		//disableFilters: true,
	},
	{
		Header : 'Bonus amount Delivery orders',
		Footer : 'Bonus amount Delivery orders',
		accessor: 'bonusAmountDelivery',
		Filter: ColumnFilter,
		// Cell: ({ value }) => {return hasAValue(value) && value.length},
		//disableFilters: true,
	},


]
