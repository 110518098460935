import React,{useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';


//Import Components
import { ThemeContext } from "../../config/context/ThemeContext";



const Home = () => {
	const { changeBackground } = useContext(ThemeContext);
	useEffect(() => {
		changeBackground({ value: "light", label: "Light" });
	}, []);

	return(
		<>
			<div className="row">
				<div className="col-xl-12">
					<div className="row">
						<div className="col-xl-12">
							<div className="row">
								<div className="col-md-4 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<div className="">
													<h2 className="mb-0 font-w600">Welcome YallaGo! </h2>
													{/*<p className="mb-0 text-nowrap">New Booking</p>*/}
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default Home;
