import React, {useEffect, useMemo, useState} from 'react';
import {useTable, useGlobalFilter, useSortBy, useFilters, usePagination} from 'react-table';
import '../../../components/FilteringTable/filtering.css';

import {toast} from "react-toastify";
import {Modal} from "react-bootstrap";

import {bonus_rules_col, orders_col} from "../../../components/FilteringTable/Columns";
import {GlobalFilter} from "../../../components/FilteringTable/GlobalFilter";
import {addNewBonusLawCall, getAllBonusLawCall, getAllBonusRulesByLawIdCall} from "../../../Api/ApiCalls";
import Swal from "sweetalert";
import {appToastMessages, toastOptions} from "../../../config/Constants";
import {hasAValue} from "../../../utils/SharedFunctions";

export default function BonusRules() {
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showView, setShowView] = useState(false)


    const [bonusLaw, setBonusLaw] = useState(undefined)
    const [bonusRules, setBonusRules] = useState([])
    const [law_name, setLaw_name] = useState(undefined)
    const [rule_list, setRule_list] = useState([{trips: 0, km: 0, bonus_amount: 0, acceptance_rate: 0 }])


    useEffect(() => {
        setColumns(bonus_rules_col)
        getAllBonusLaw()
    }, [])


    const tableInstance = useTable({
        columns,
        data,
        initialState: {pageIndex: 0}
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance


    const {globalFilter, pageIndex} = state


    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">bonus rules</h4>
                    <a className="btn btn-secondary" onClick={() => openModalAdd()}>+ Add new low</a>

                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
                        <table {...getTableProps()} className="table dataTable display">
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span className="ml-1">
                                                {column.isSorted ? (
                                                        column.isSortedDesc ?
                                                            <i className="fa fa-arrow-down ms-2 fs-14"
                                                               style={{opacity: '0.7'}}/>
                                                            :
                                                            <i className="fa fa-arrow-up ms-2 fs-14"
                                                               style={{opacity: '0.7'}}/>
                                                    )
                                                    :
                                                    (<i className="fa fa-sort ms-2 fs-14"
                                                        style={{opacity: '0.3'}}/>)}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>

                            {page.map((row) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()} className={"cursor-pointer"}
                                                       onClick={() => openModalView(row.original)}> {cell.render('Cell')} </td>
                                        })}

                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        {/* This is only for footer if u require */}
                        <div className="d-flex justify-content-between">
							<span>
								Page{' '}
                                <strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
                            <span className="table-index">
								Go to page : {' '}
                                <input type="number"
                                       className="ml-2"
                                       defaultValue={pageIndex + 1}
                                       onChange={e => {
                                           const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                           gotoPage(pageNumber)
                                       }}
                                />
							</span>
                        </div>
                        <div className="text-center mb-3">
                            <div className="filter-pagination  mt-3">
                                <button className=" previous-button" onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}>{'<<'}</button>

                                <button className="previous-button" onClick={() => previousPage()}
                                        disabled={!canPreviousPage}>
                                    Previous
                                </button>
                                <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                    Next
                                </button>
                                <button className=" next-button" onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}>{'>>'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal className="modal fade" size={"xl"} show={showModal} onHide={setShowModal}>
                <div className="" role="document">
                    <div className="">
                        <form>
                            <div className="modal-header">
                                <h4 className="modal-title fs-20">New bonus law</h4>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)}
                                        data-dismiss="modal"></button>
                            </div>
                            <div className="modal-body">
                                <i className="flaticon-cancel-12 close"></i>
                                {!showView && <div className="add-contact-box">
                                    <div className="add-contact-content">
                                        <div className="form-group mb-3">
                                            <label className="text-black font-w500">Law name</label>
                                            <div className="contact-name">
                                                <input type="text" className="form-control"
                                                       name="title" required="required"
                                                       defaultValue={law_name}
                                                       onChange={(val) => setLaw_name(val.target.value)}

                                                />
                                            </div>
                                        </div>
                                        <a className="btn btn-secondary" onClick={() => {
                                            setRule_list(oldArray => [...oldArray, {trips: 0, km: 0, bonus_amount: 0, acceptance_rate: 0 }]);
                                        }}>+ Add new rule</a>

                                        {rule_list.map((item, i) => {
                                            return (
                                                <div className="form-group mb-3">
                                                    <label className="text-black font-w500 mt-3">New rule</label>
                                                    <div className="contact-name row">
                                                        <div className="col-md-3 col-sm-6 m-sm-auto">
                                                            <label className="text-black font-w500 mt-3">Trips</label>
                                                            <input type="number" className="form-control"
                                                                   name="trips" required="required"
                                                                   defaultValue={rule_list[i].trips}
                                                                   min={0}
                                                                   onChange={(val) => {
                                                                       rule_list[i]['trips'] = parseInt(val.target.value)
                                                                       setRule_list(rule_list)
                                                                   }}
                                                            />
                                                        </div>
                                                        <div className="col-md-3 col-sm-6 m-sm-auto">
                                                            <label className="text-black font-w500 mt-3">KM</label>
                                                            <input type="number" className="form-control"
                                                                   name="km" required="required"
                                                                   defaultValue={rule_list[i].km}
                                                                   min={0}
                                                                   onChange={(val) => {
                                                                       rule_list[i]['km'] = parseInt(val.target.value)
                                                                       setRule_list(rule_list)
                                                                   }}
                                                            />
                                                        </div>

                                                        <div className="col-md-3 col-sm-6 m-sm-auto">
                                                            <label className="text-black font-w500 mt-3">bonus</label>
                                                            <input type="number" className="form-control"
                                                                   name="bonus" required="required"
                                                                   defaultValue={rule_list[i].bonus_amount}
                                                                   min={0}
                                                                   onChange={(val) => {
                                                                       rule_list[i]['bonus_amount'] = parseInt(val.target.value)
                                                                       setRule_list(rule_list)
                                                                   }}
                                                            />
                                                        </div>

                                                        <div className="col-md-3 col-sm-6 m-sm-auto">
                                                            <label className="text-black font-w500 mt-3">Acceptance rate</label>
                                                            <input type="number" className="form-control"
                                                                   name="acceptance_rate" required="required"
                                                                   defaultValue={rule_list[i].acceptance_rate}
                                                                   min={0}
                                                                   onChange={(val) => {
                                                                       rule_list[i]['acceptance_rate'] = parseInt(val.target.value)
                                                                       setRule_list(rule_list)
                                                                   }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>}

                                {showView && <div className="add-contact-box">
                                    <div className="add-contact-content">
                                        <div className="form-group mb-3">
                                            <label className="text-black font-w500">Law name</label>
                                            <div className="contact-name">
                                                <h4>{bonusLaw.name}</h4>
                                                {/*<input type="text" className="form-control"*/}
                                                {/*       name="title" required="required"*/}
                                                {/*       defaultValue={law_name}*/}
                                                {/*       onChange={(val) => setLaw_name(val.target.value)}*/}

                                                {/*/>*/}
                                            </div>
                                        </div>

                                        {bonusRules.map((item, i) => {
                                            return (
                                                <div className="form-group mb-3">
                                                    <div className="contact-name row">
                                                        <div className="col-md-3 col-sm-6 m-sm-auto">
                                                            <label className="text-black font-w500 mt-3">Trips</label>
                                                            <h4>{item.trips}</h4>
                                                        </div>
                                                        <div className="col-md-3 col-sm-6 m-sm-auto">
                                                            <label className="text-black font-w500 mt-3">KM</label>
                                                            <h4>{item.km}</h4>
                                                        </div>
                                                        <div className="col-md-3 col-sm-6 m-sm-auto">
                                                            <label className="text-black font-w500 mt-3">bonus</label>
                                                            <h4>{item.bonus}</h4>
                                                        </div>
                                                        <div className="col-md-3 col-sm-6 m-sm-auto">
                                                            <label className="text-black font-w500 mt-3">Acceptance rate</label>
                                                            <h4>{item.acceptance_rate}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>}
                            </div>
                            {!showView && <div className="modal-footer">

                                <button type="button" onClick={() => setShowModal(false)} className="btn btn-danger"><i
                                    className="flaticon-delete-1"></i> Ignore changes
                                </button>
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => {
                                            addNewBonusLaw()
                                        }}>Add
                                </button>
                            </div>}

                            {/*{showView && <div className="modal-footer">*/}
                            {/*    <button type="button" className="btn btn-secondary"*/}
                            {/*            onClick={() => updateNewsItem()}>Bijwerken</button>*/}
                            {/*    <button type="button" className="btn btn-danger"*/}
                            {/*            onClick={() => deleteNewsItem()}>Verwijderen</button>*/}
                            {/*    <button type="button" onClick={() => setShowModal(false)} className="btn btn-warning"><i*/}
                            {/*        className="flaticon-delete-1"></i> Wijzigingen negeren*/}
                            {/*    </button>*/}
                            {/*</div>}*/}
                        </form>

                    </div>
                </div>
            </Modal>
        </>
    )


    function openModalAdd() {
        setShowModal(true)
        setShowView(false)
        setRule_list([])
        setLaw_name(undefined)
    }

    function openModalView(item) {
        setBonusLaw(item)
        getAllBonusRulesByLawId(item.id)
        setShowModal(true)
        setShowView(true)
    }

    function addNewBonusLaw() {
        if(hasAValue(law_name) && rule_list.length> 0){
            Swal({
                title: "Are you sure?",
                text: "You want to change the status!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((response) => {
                if (response) {
                    let data = {law_name, rule_list}
                    addNewBonusLawCall(data).then(res => {
                        setShowModal(false)
                        getAllBonusLaw()
                        toast.success(res.data.message, toastOptions);
                    }).catch(error => {
                        toast.error(error.response.data.error, toastOptions);
                    })
                } else {
                    toast.error(appToastMessages.action_canceled, toastOptions);
                }
            })
        }else{
            toast.error(appToastMessages.all_fields_are_required, toastOptions);
        }


    }

    function getAllBonusLaw(){
        getAllBonusLawCall().then(list => {
            console.log(list)
            setData(list)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }

    function getAllBonusRulesByLawId(id){
        getAllBonusRulesByLawIdCall(id).then(list => {
            console.log(list)
            setBonusRules(list)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }

    //
    // function getOrderSticker(id){
    //     getOrderStickerCall(id).then(order => {
    //         if(hasAValue(order.sticker)){
    //             window.open('data:image/png;base64,' + arrayBufferToBase64(order.sticker.data))
    //         }else{
    //             toast.error(appToastMessages.not_paid_order, toastOptions);
    //         }
    //     }).catch(error => {
    //         toast.error(error.response.data.error, toastOptions);
    //     })
    // }
    //
    //
    // function getPaymentStatusAdmin(code){
    //     getPaymentStatusAdminCall(code).then(order => {
    //         toast.success(appToastMessages.done, toastOptions);
    //     }).catch(error => {
    //         toast.error(error.response.data.error, toastOptions);
    //     })
    // }


    //
    // function arrayBufferToBase64(buffer) {
    //     let binary = '';
    //     let bytes = new Uint8Array(buffer);
    //     let len = bytes.byteLength;
    //     for (let i = 0; i < len; i++) {
    //         binary += String.fromCharCode(bytes[i]);
    //     }
    //     return (binary);
    // }
    //
    // function updateOrderDeliveryLink(order){
    //     if(hasAValue(orderDeliveryLink)){
    //         Swal({
    //             title: "Are you sure?",
    //             text: "You want to change the status!",
    //             icon: "warning",
    //             buttons: true,
    //             dangerMode: true,
    //         }).then((response) => {
    //             if (response) {
    //                 let data = {id: order.id, link: orderDeliveryLink}
    //                 updateOrderDeliveryLinkCall(data).then(res => {
    //                     getAllOrder()
    //                     toast.success(res.data.message, toastOptions);
    //                 }).catch(error => {
    //                     toast.error(error.response.data.error, toastOptions);
    //                 })
    //             } else {
    //                 toast.error(appToastMessages.action_canceled, toastOptions);
    //             }
    //         })
    //     }else{
    //         toast.error(appToastMessages.all_fields_are_required, toastOptions);
    //     }
    // }
    //
    // function updateOrderStatus(order, status){
    //     Swal({
    //         title: "Are you sure?",
    //         text: "You want to change the status!",
    //         icon: "warning",
    //         buttons: true,
    //         dangerMode: true,
    //     }).then((response) => {
    //         if (response) {
    //             let data = {id: order.id, status: status}
    //             updateOrderStatusCall(data).then(res => {
    //                 getAllOrder()
    //                 toast.success(res.data.message, toastOptions);
    //             }).catch(error => {
    //                 toast.error(error.response.data.error, toastOptions);
    //             })
    //         } else {
    //             toast.error(appToastMessages.action_canceled, toastOptions);
    //         }
    //     })
    // }
    //
    // function updateCustomerInfo(order){
    //     Swal({
    //         title: "Are you sure?",
    //         text: "You want to change the status!",
    //         icon: "warning",
    //         buttons: true,
    //         dangerMode: true,
    //     }).then((response) => {
    //         if (response) {
    //             let data = {id: order.id, address, url, city, postal_code, owner_email, license_plate}
    //             updateCustomerInfoCall(data).then(res => {
    //                 getAllOrder()
    //                 toast.success(res.data.message, toastOptions);
    //             }).catch(error => {
    //                 toast.error(error.response.data.error, toastOptions);
    //             })
    //         } else {
    //             toast.error(appToastMessages.action_canceled, toastOptions);
    //         }
    //     })
    // }

}
